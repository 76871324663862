import React, { useState, useEffect } from 'react';
import './Chat.css';
import SpeechToText from './SpeechToText';

const Chat = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showSources, setShowSources] = useState(null);
    const [lastSearchResults, setLastSearchResults] = useState('');
    const [model, setModel] = useState(localStorage.getItem('model') || 'mistral-nemo');
    const [sourceCheck, setSourceCheck] = useState(localStorage.getItem('sourceCheck') === null || localStorage.getItem('sourceCheck') === 'true');
    const [otherLanguage, setOtherLanguage] = useState(localStorage.getItem('otherLanguage') === 'true');
    const [ttsEnabled, setTtsEnabled] = useState(true);

    const audioQueue = [];
    let currentAudio = null;
    let isProcessingQueue = false; 

    const token = localStorage.getItem("token");

    const toggleChat = () => setIsOpen(!isOpen);

    useEffect(() => {
        localStorage.setItem('model', model);
    }, [model]);

    useEffect(() => {
        localStorage.setItem('sourceCheck', sourceCheck);
    }, [sourceCheck]);

    useEffect(() => {
        localStorage.setItem('otherLanguage', otherLanguage);
    }, [otherLanguage]);

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
/*
    useEffect(() => {
        if (messages.length === 0) return;
    
        const lastMessage = messages[messages.length - 1];
    
        if (lastMessage && !lastMessage.fromUser && ttsEnabled && !lastMessage.temp) {
            const sentenceDelimiters = /[.!?]+/g;
            const sentences = lastMessage.text.split(sentenceDelimiters).filter(Boolean);
    
            for (const sentence of sentences) {
                handleTtsQueue(sentence.trim()); // Enqueue sentences instead of calling generateAndPlayAudio
            }
        }
    }, [messages, ttsEnabled]);    
    */

    useEffect(() => {
        if (!ttsEnabled) {
            stopAllAudio();
        }
    }, [ttsEnabled]);    

    const handleTtsQueue = (text) => {
        if (!text) return;
    
        // Add text to the queue
        audioQueue.push(text);
    
        // Start processing the queue if not already
        if (!isProcessingQueue) {
            processAudioQueue();
        }
    };
    const processAudioQueue = async () => {
        if (audioQueue.length === 0) {
            isProcessingQueue = false;
            return;
        }

        // Stop processing if TTS is disabled
        if (!ttsEnabled) {
            isProcessingQueue = false;
            audioQueue.length = 0; // Clear the queue
            return;
        }

        isProcessingQueue = true;

        const sentence = audioQueue.shift(); // Get the next sentence
        try {
            await generateAndPlayAudio(sentence);
        } catch (error) {
            console.error("Error in TTS:", error);
        } finally {
            // Process the next item in the queue
            processAudioQueue();
        }
    };
    
    const playAudio = (audio) => {
        return new Promise((resolve, reject) => {
            audio.addEventListener("ended", resolve);
            audio.addEventListener("error", reject);
            audio.play();
        });
    };
    
    const stopAllAudio = () => {
        audioQueue.length = 0; // Clear the queue
        if (currentAudio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
            currentAudio = null;
        }
    };

    const handleSendMessageKeyDown = async (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            await sendMessage();
        }
    };

    const sendMessage = async () => {
        if (message.trim() !== '') {
            const newMessage = { text: message, fromUser: true };
            setMessages(messages => [...messages, newMessage]);
            setMessage('');
            setLoading(true);
            await sendSearchRequest(message);
        }
    };

    const handleClickSendMessage = async () => {
        await sendMessage();
    };

    const handleSpeechInput = (transcript) => {
        setMessage(transcript);
    };

    const sendSearchRequest = async (query, k_search_results = 8) => {
        try {
            const response = await fetch('https://dellfi.serv.uni-hohenheim.de/backend/api/search', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ query, top_k: k_search_results })
            });
            if (!response.ok) throw new Error('Failed to fetch search results.');
            let searchData = await response.json();
            searchData = Array.isArray(searchData) ? searchData : [];
            await sendLLMRequest(searchData, query);
        } catch (error) {
            console.error('Search request failed:', error);
            setMessages(messages => [...messages, { text: 'Failed to fetch search results.', fromUser: false }]);
        } finally {
            setLoading(false);
        }
    };

    const callSourceSimilarityEndpoint = async (searchResults, verifyMessage) => {
        const sources = searchResults.map(result => result.text);

        console.log("sending last llm message", verifyMessage)
        console.log("sending sources", sources)

        try {
            const response = await fetch('https://dellfi.serv.uni-hohenheim.de/backend/api/source_similarity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ verify_message: verifyMessage, sources })
            });
            if (!response.ok) throw new Error('Failed to fetch similarity results.');
            const similarityData = await response.json();
            console.log('Similarity results:', similarityData);

            // Process similarity results
            const sentencesWithColorsAndSources = similarityData.map(result => {
                const { color, sources } = getColorAndSources(result.similarities);
                return {
                    text: result.sentence,
                    color,
                    sources,
                    start_index: result.start_index,
                    end_index: result.end_index,
                };
            });

            console.log("Processed sentencesWithColorsAndSources: ", sentencesWithColorsAndSources);
            console.log("Messages before update: ", messages);

            setMessages(messages => {
                const updatedMessages = messages.map((msg, index) =>
                    index === messages.length - 1
                        ? {
                            ...msg,
                            text: verifyMessage,
                            sentencesWithColorsAndSources: sentencesWithColorsAndSources
                        }
                        : msg
                );
                console.log("Updated Messages: ", updatedMessages);
                return updatedMessages;
            });

        } catch (error) {
            console.error('Similarity request failed:', error);
        }
    };

    const getColorAndSources = (similarities) => {
        let highestScore = 0;
        let color = '#79651b';
        let sources = [];
    
        similarities.forEach(similarity => {
            if (similarity.similarity_score > highestScore) {
                highestScore = similarity.similarity_score;
                sources = [similarity.source];
            }
        });

        if (sources[0] > 0.8 && sources[0] <= 0.83){
            let temp = sources[0];
            sources = [];
            sources.push('Folgende Quelle ist am ähnlichsten aber möglicherweise unpassend\n:');
            sources.push(temp);
        }
    
        if (highestScore > 0.9) {
            color = '#006400';
        } else if (highestScore > 0.88) {
            color = '#008000';
        } else if (highestScore > 0.855) {
            color = '#499d4c';
        } else if (highestScore > 0.84) {
            color = '#79a74c';
        } else if (highestScore > 0.83) {
            color = '#92ad59';
        } else if (highestScore > 0.8) {
            color = '#9b9b22';
            /*if (sources.length === 1) {
                sources.push('Folgende Quelle ist am ähnlichsten aber möglicherweise unpassend\n:');
            }*/
        }
    
        if (highestScore < 0.8) { //sources.length === 0
            sources = []; 
            sources.push('No sources found');
        }
    
        return { color, sources };
    };
    


    const createChatHistory = (messages, numMessages = 6) => {
        return messages.slice(-numMessages).map(msg => `${msg.fromUser ? 'User: ' : 'LLM: '}${msg.text}`).join('\n');
    };

    //TODO - Add last source
    const sendLLMRequest = async (searchResults, userQuery) => {

        if(otherLanguage == false){
            // .45 can be changed it's just based on exp and works for now -> Its there to sift out trash and e5-large specific...
            searchResults = searchResults.filter(result => result.distance <= 0.45);
        }

        const chatHistory = createChatHistory(messages);

        let customPrompt = "der Deutschen Rentenversicherung (DRV) und du beantwortest Mitarbeitern Fragen basierend auf den hier bereitgestellten internen informationen";
        customPrompt = "";

        let prompt;
        if (searchResults.length > 0) {

            const top3Results = searchResults.slice(0, 3);
            const searchResultsText = top3Results.map(result => result.text).join('\n\n');

            const top2Results = searchResults.slice(0, 3);
            const lastResultsText = top2Results.map(result => result.text).join('\n\n');

            const previousLastSearchResults = lastSearchResults;
            setLastSearchResults(lastResultsText);

            prompt = `Letzte Suchergebnisse: ${previousLastSearchResults}. Vorherige Konversation: ${chatHistory} Ende des bisherigen Chatverlaufs.\n\nDu bist der deutsche Chatbot PET (PEdagogical conversational Tutor) "${customPrompt}": der Nutzer fragt: "${userQuery}". Basierend auf einer Systemsuche wurden diese Texte gefunden:\n\n${searchResultsText}\n\nBasierend auf der Nutzeranfrage "${userQuery}" antworte auf dessen Anfrage ohne hervorzuheben, dass es eine Systemsuche gab. Antworte dem Nutzer kurz auf Deutsch:`;
        } else {
            if(chatHistory.length > 10)
                prompt = `${chatHistory} Ende des bisherigen Chatverlaufs.\n\nDu bist der deutsche Chatbot PET (PEdagogical conversational Tutor): Der Nutzer fragt: "${userQuery}". Antworte dem Nutzer direkt und kurz auf Deutsch:`;
            else
                prompt = `Du bist der deutsche Chatbot PET (PEdagogical conversational Tutor): Der Nutzer fragt: "${userQuery}". Antworte dem Nutzer direkt und kurz auf Deutsch:`;
        }

        console.log("Search Results", searchResults);
        console.log("prompt", prompt);

        try {
            const response = await fetch('https://dellfi.serv.uni-hohenheim.de/backend/api/llm_inference', { //'https://dellfi.serv.uni-hohenheim.de/backend/mistral' or 'http://localhost:11434/api/generate'
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                 },
                body: JSON.stringify({ priority: 'high', model: model, prompt }) //'mistral:v0.2'
            });
            if (!response.ok) throw new Error('LLM response not OK.');
    
            const reader = response.body.getReader();
            console.log("Reader: ", reader);
            let completeMessage = '';
            let tempMessageAdded = false;  // Flag to track if a temporary message has been added
    
            let accumulatedData = '';
            let sentenceBuffer = '';
            let isThinking = false;

            function handleStream({ done, value }) {
                if (done) {
                    // Finalize any remaining buffered sentence
                    if (sentenceBuffer.trim()) {
                        handleTtsQueue(sentenceBuffer.trim());
                    }
                    setMessages(messages => messages.map(msg =>
                        msg.temp ? { ...msg, text: completeMessage, temp: false } : msg
                    ));
                    setLoading(false);
                    if (completeMessage !== "" && sourceCheck) {
                        callSourceSimilarityEndpoint(searchResults, completeMessage);
                    }
                    return;
                }
            
                const textDecoder = new TextDecoder("utf-8");
                accumulatedData += textDecoder.decode(value, { stream: true });
            
                while (true) {
                    let jsonBoundary = accumulatedData.indexOf('}');
                    if (jsonBoundary === -1) break;
            
                    try {
                        const possibleJson = accumulatedData.slice(0, jsonBoundary + 1);
                        const jsonChunks = JSON.parse(possibleJson);
            
                        if (jsonChunks && jsonChunks.response !== undefined) {
                            let textChunk = jsonChunks.response;
                        
                            // Handle <think> tags
                            if (textChunk.includes('<think>')) {
                                const startIdx = textChunk.indexOf('<think>');
                                const endIdx = textChunk.indexOf('</think>');
                        
                                if (startIdx !== -1 && endIdx !== -1) {
                                    // If both <think> and </think> are in the same chunk, replace the entire range
                                    textChunk = `${textChunk.slice(0, startIdx)}thinking...${textChunk.slice(endIdx + 8)}`;
                                } else {
                                    // If <think> is detected without </think>, start skipping
                                    isThinking = true;
                                    textChunk = `${textChunk.slice(0, startIdx)}thinking...`;
                                }
                            } else if (isThinking && textChunk.includes('</think>')) {
                                // If </think> is found while skipping, stop skipping
                                const endIdx = textChunk.indexOf('</think>');
                                isThinking = false;
                                completeMessage = completeMessage.replace(/thinking\.\.\.$/, '');
                                textChunk = textChunk.slice(endIdx + 8); // Remove </think> and continue
                            } else if (isThinking) {
                                // If inside a <think> section, skip the entire chunk
                                textChunk = '';
                            }
                        
                            completeMessage += textChunk;
                        
                            // Process sentences for normal streaming
                            const sentenceDelimiters = /[.!?]+/g;
                            const parts = (sentenceBuffer + textChunk).split(sentenceDelimiters);
                        
                            // Process complete sentences
                            for (let i = 0; i < parts.length - 1; i++) {
                                handleTtsQueue(parts[i].trim());
                            }
                        
                            // Save the last (incomplete) part to the buffer
                            sentenceBuffer = parts[parts.length - 1];
                        }
            
                        accumulatedData = accumulatedData.slice(jsonBoundary + 1);
            
                        // Update the temporary message with the latest chunk
                        if (tempMessageAdded) {
                            setMessages(messages => messages.map(msg =>
                                msg.temp ? { ...msg, text: completeMessage } : msg
                            ));
                        } else {
                            setMessages(messages => [...messages, { text: completeMessage, fromUser: false, temp: true }]);
                            tempMessageAdded = true;
                        }
            
                    } catch (e) {
                        console.warn('Incomplete JSON, waiting for more chunks...', e);
                        break;
                    }
                }
            
                // Continue reading the stream
                reader.read().then(handleStream).catch((error) => {
                    console.error('Error while reading stream:', error);
                    setMessages(messages => [...messages, { text: 'Error reading the LLM stream.', fromUser: false }]);
                    setLoading(false);
                });
            }
            
    
            reader.read().then(handleStream);
        } catch (error) {
            console.error('LLM communication failed:', error);
            setMessages(messages => [...messages, { text: 'Failed to communicate with LLM.', fromUser: false }]);
            setLoading(false);
        }
    };


    const handleSentenceClick = (sentenceIndex) => {
        setShowSources(showSources === sentenceIndex ? null : sentenceIndex);
    };

    const handleModelChange = (event) => {
        setModel(event.target.value);
    };
    
    const handleSourceCheckChange = (event) => {
        setSourceCheck(event.target.checked);
    };
    
    const handleOtherLanguageChange = (event) => {
        setOtherLanguage(event.target.checked);
    };

    const handleTtsToggleChange = (event) => {
        const isEnabled = event.target.checked;
        setTtsEnabled(isEnabled);
    
        if (!isEnabled) {
            stopAllAudio(); // Stop the current audio and clear the queue
        }
    };
    

    const generateAndPlayAudio = async (text) => {
        try {
            const response = await fetch("https://dellfi.serv.uni-hohenheim.de/backend/api/tts", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    text: text,
                    language: "de", // Adjust as needed
                }),
            });
    
            if (response.ok) {
                const blob = await response.blob();
                const audioUrl = URL.createObjectURL(blob);
    
                const audio = new Audio(audioUrl);
                await playAudio(audio);
    
                // Clean up the audio URL
                URL.revokeObjectURL(audioUrl);
            } else {
                console.error("TTS API call failed", await response.text());
                throw new Error("TTS API call failed");
            }
        } catch (error) {
            console.error("Error generating or playing audio:", error);
            throw error;
        }
    };


    const renderMessage = (msg, index) => {
        if (msg.fromUser || !msg.sentencesWithColorsAndSources) {
            return (
                <div
                    key={index}
                    className={`message ${msg.fromUser ? 'right-msg' : 'left-msg'}`}
                    style={{ backgroundColor: msg.fromUser ? '#1976d2' : '#90caf9' }}
                >
                    {msg.text}
                </div>
            );
        }
    
        return (
            <div
                key={index}
                className={`message ${msg.fromUser ? 'right-msg' : 'left-msg'}`}
                style={{ backgroundColor: '#90caf9' }}
            >
                {msg.sentencesWithColorsAndSources.map((sentence, i) => (
                    <span
                        key={i}
                        onClick={() => handleSentenceClick(i)}
                        style={{
                            backgroundColor: sentence.color,
                            cursor: 'pointer',
                            display: 'inline',
                            margin: '2px',
                            padding: '2px',
                            borderRadius: '4px',
                            position: 'relative'
                        }}
                    >
                        <span className="sentence" key={i}>
                            {sentence.text}
                            {showSources === i && (
                                <div className="source-popup">
                                    {sentence.sources.map((source, k) => (
                                        <div key={k}>{source}</div>
                                    ))}
                                </div>
                            )}
                        </span>
                    </span>
                ))}
            </div>
        );
    };


    return (
        <div>
            <button className="chat-toggle-button" onClick={toggleChat}>
                {isOpen ? 'Close' : 'Chat'}
            </button>

            {isOpen && (
                <div className="chat-container">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <select className="model-selector" value={model} onChange={handleModelChange}>
                            <option value="mistral-nemo">Mistral Nemo</option>
                            <option value="deepseek-r1:14b">DeepSeek-R1</option>
                        </select>
                        <div className="toggle-container">
                            <label className="switch">
                                <input type="checkbox" checked={ttsEnabled} onChange={handleTtsToggleChange} />
                                <span className="slider round"></span>
                            </label>
                            <span className="toggle-label">Vorlesen</span>
                        </div>
                    </div>

                    <div className="messages">
                        {messages.map((msg, index) => renderMessage(msg, index))}
                        {loading && <div className="message left-msg">...</div>}
                    </div>
                    <div className="message-input-container">
                        <SpeechToText onInput={handleSpeechInput} className="speech-to-text-icon" />
                        <textarea
                            className="message-input"
                            placeholder="Type a message..."
                            value={message}
                            onChange={handleMessageChange}
                            onKeyDown={handleSendMessageKeyDown}
                        />
                    </div>
                    <button className="send-button" onClick={handleClickSendMessage}>Send</button>
                    <div className="toggle-container">
                        <label className="switch">
                            <input type="checkbox" checked={sourceCheck} onChange={handleSourceCheckChange} />
                            <span className="slider round"></span>
                        </label>
                        <span className="toggle-label">Quellen-Check</span>

                        <label className="switch">
                            <input type="checkbox" checked={otherLanguage} onChange={handleOtherLanguageChange} />
                            <span className="slider round"></span>
                        </label>
                        <span className="toggle-label">Fremdsprachen-Optimierung</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chat;
