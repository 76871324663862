import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button, Card, CardContent, Typography, CircularProgress, Grid,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './Quiz.css';

const Quiz = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [quizToDelete, setQuizToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const response = await fetch('https://dellfi.serv.uni-hohenheim.de/backend/quizzes/owned', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setQuizzes(data);
        } else {
          console.error('Error fetching quizzes:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching quizzes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuizzes();
  }, []);

  const handleEditQuiz = (quizId) => {
    navigate(`/edit-quiz/${quizId}`);
  };

  const confirmDeleteQuiz = (quizId) => {
    setQuizToDelete(quizId);
    setDeleteModalOpen(true);
  };

  const handleDeleteQuiz = async () => {
    if (!quizToDelete) return;

    try {
      const response = await fetch(`https://dellfi.serv.uni-hohenheim.de/backend/quiz/${quizToDelete}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        setQuizzes((prevQuizzes) => prevQuizzes.filter((quiz) => quiz.id !== quizToDelete));
      } else {
        console.error('Error deleting quiz:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting quiz:', error);
    } finally {
      setDeleteModalOpen(false);
      setQuizToDelete(null);
    }
  };



  if (loading) {
    return (
      <div className="quiz-page">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="quiz-page">
      <Typography variant="h4" align="center" className="quiz-header">
        Your Quizzes
      </Typography>

      <div className="create-quiz-btn-container">
        <Button
          variant="contained"
          color="primary"
          className="create-quiz-btn"
          onClick={() => navigate('/edit-quiz')}
        >
          Create New Quiz
        </Button>
      </div>

      <Grid container spacing={3} justifyContent="center">
        {quizzes.length > 0 ? (
          quizzes.map((quiz) => (
            <Grid item xs={12} md={6} lg={4} key={quiz.id}>
              <Card className="quiz-card">
                <CardContent>
                  <Typography variant="h5">{quiz.title}</Typography>
                  <Typography variant="body1">Questions: {quiz.questions.length}</Typography>
                  <Typography variant="body1">Live: {quiz.live ? 'Yes' : 'No'}</Typography>
                  {quiz.live && <Typography variant="body1">Active Sessions: {quiz.activeSessions.length}</Typography>}
                </CardContent>
                <div className="quiz-actions">
                  <Button onClick={() => handleEditQuiz(quiz.id)} variant="contained" color="primary">
                    Edit
                  </Button>
                  <Button variant="contained" color="secondary">
                    {quiz.live ? 'Stop' : 'Start'} Live
                  </Button>
                  <IconButton onClick={() => confirmDeleteQuiz(quiz.id)} color="error">
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Card>

            </Grid>
          ))
        ) : (
          <Typography className='margin-top-40' variant="body1" align="center">
            You don't have any quizzes yet. <Link to="/create-quiz">Create one now!</Link>
          </Typography>
        )}
      </Grid>

      <Dialog open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this quiz? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteQuiz} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Quiz;