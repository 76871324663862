import React, { useState, useRef } from "react";
import { TextField, Button, CircularProgress, Input, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import "./VoiceSynthesis.css";

const VoiceSynthesis = () => {
    const [recording, setRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const [uploadedAudio, setUploadedAudio] = useState(null);
    const [generatedAudio, setGeneratedAudio] = useState(null);
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("de");
    const mediaRecorder = useRef(null);
    const token = localStorage.getItem("token");

    const languages = [
        { code: "en", name: "English" },
        { code: "de", name: "German" },
        { code: "es", name: "Spanish" },
        { code: "fr", name: "French" },
        { code: "zh", name: "Chinese" },
        { code: "ja", name: "Japanese" },
        { code: "ru", name: "Russian" },
        { code: "it", name: "Italian" },
        { code: "pt", name: "Portuguese" },
        { code: "ar", name: "Arabic" },
        { code: "hi", name: "Hindi" },
        { code: "ko", name: "Korean" },
        { code: "nl", name: "Dutch" },
        { code: "sv", name: "Swedish" },
        { code: "da", name: "Danish" },
        { code: "fi", name: "Finnish" },
        { code: "no", name: "Norwegian" },
        { code: "pl", name: "Polish" },
        { code: "tr", name: "Turkish" },
        { code: "he", name: "Hebrew" },
    ];

    // Function to start recording
    const handleRecordStart = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);
            mediaRecorder.current = recorder;

            const chunks = [];
            recorder.ondataavailable = (e) => chunks.push(e.data);
            recorder.onstop = () => {
                const blob = new Blob(chunks, { type: "audio/wav" });
                setAudioBlob(blob);
            };

            recorder.start();
            setRecording(true);
        } catch (error) {
            console.error("Error accessing microphone:", error);
            alert("Could not access the microphone. Please check your permissions.");
        }
    };

    // Function to stop recording
    const handleRecordStop = () => {
        if (mediaRecorder.current) {
            mediaRecorder.current.stop();
            setRecording(false);
        }
    };

    // Function to handle audio file upload
    const handleUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type !== "audio/wav" && !file.name.endsWith(".wav")) {
                alert("Only .wav files are allowed!");
                return;
            }
            setUploadedAudio(file);
        }
    };

    // Function to generate voice synthesis
    const generateAudio = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("text", text);
            formData.append("language", selectedLanguage || "de");

            // Use either recorded or uploaded audio as reference
            const referenceAudio = uploadedAudio || audioBlob;
            if (referenceAudio) {
                formData.append("speaker_wav", referenceAudio, "speaker.wav");
            }

            const response = await fetch("https://dellfi.serv.uni-hohenheim.de/backend/api/tts", {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });


            if (response.ok) {
                const blob = await response.blob();
                setGeneratedAudio(blob);
            } else {
                console.error("TTS API call failed", await response.text());
            }
        } catch (error) {
            console.error("Error generating audio:", error);
        } finally {
            setLoading(false);
        }
    };

    // Function to download audio
    const downloadAudio = (audioBlob, fileName) => {
        const audioUrl = URL.createObjectURL(audioBlob);
        const link = document.createElement("a");
        link.href = audioUrl;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(audioUrl);
    };

    return (
        <div className="voice-synthesis-container">
            <h1>Voice Synthesis</h1>

            {/* Record Voice Section - Big issue -> Recoring is not wav and wed need a ffmpeg worker and currently i dont want even more bloat.*/}
            {/*             <div className="section">
                <h2>Record Your Voice</h2>
                <Button
                    variant="contained"
                    color={recording ? "secondary" : "primary"}
                    onClick={recording ? handleRecordStop : handleRecordStart}
                >
                    {recording ? "Stop Recording" : "Start Recording"}
                </Button>
            </div> */}

            {/* Upload Reference Audio */}
            <div className="section">
                <h2>Upload Reference Voice (must be .wav)</h2>
                <Input type="file" accept="audio/wav" onChange={handleUpload} />
            </div>

            <div className="section">
                <h2>Select Language</h2>
                <FormControl fullWidth>
                    <InputLabel>Language</InputLabel>
                    <Select
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                    >
                        {languages.map((lang) => (
                            <MenuItem key={lang.code} value={lang.code}>
                                {lang.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            {/* Text Input for Voice Synthesis */}
            <div className="section">
                <h2>Generate Synthetic Voice</h2>
                <TextField
                    label="Enter text for synthesis"
                    variant="outlined"
                    fullWidth
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={generateAudio}
                    disabled={loading || !text.trim()}
                >
                    {loading ? <CircularProgress size={24} /> : "Generate Audio"}
                </Button>
            </div>

            {/* Audio Controls for Recorded, Uploaded, and Generated Audio */}
            <div className="section">
                {audioBlob && (
                    <>
                        <h3>Recorded Audio:</h3>
                        <audio controls src={URL.createObjectURL(audioBlob)}></audio>
                        <Button onClick={() => downloadAudio(audioBlob, "recorded_audio.wav")}>
                            Download
                        </Button>
                    </>
                )}
                {uploadedAudio && (
                    <>
                        <h3>Uploaded Audio:</h3>
                        <audio controls src={URL.createObjectURL(uploadedAudio)}></audio>
                        <Button onClick={() => downloadAudio(uploadedAudio, "uploaded_audio.wav")}>
                            Download
                        </Button>
                    </>
                )}
                {generatedAudio && (
                    <>
                        <h3>Generated Audio:</h3>
                        <audio controls src={URL.createObjectURL(generatedAudio)}></audio>
                        <Button onClick={() => downloadAudio(generatedAudio, "synthesized_audio.wav")}>
                            Download
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default VoiceSynthesis;
