import React, { useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    TextField,
    Typography,
    IconButton,
    Snackbar,
    Alert,
    Paper,
} from '@mui/material';
import { FileUpload as FileUploadIcon, ContentCopy as CopyIcon } from '@mui/icons-material';
import './Transcription.css';

const Transcription = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [jobId, setJobId] = useState(null);
    const [transcript, setTranscript] = useState('');
    const [jsonTranscript, setJsonTranscript] = useState('');
    const [polling, setPolling] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    const token = localStorage.getItem("token");

    // Handles file selection
    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    // Handles file upload
    const handleFileUpload = async () => {
        if (!selectedFile) {
            alert("Please select a file first.");
            return;
        }

        setUploading(true);
        setPolling(false);
        setTranscript('');
        setJsonTranscript('');

        const formData = new FormData();
        formData.append("audio", selectedFile); // Ensure the field name matches what the backend expects

        try {
            const response = await fetch("https://dellfi.serv.uni-hohenheim.de/backend/api/transcription/enqueue", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`, // Keep only Authorization header
                    // DO NOT manually set "Content-Type" for FormData; the browser sets it correctly
                },
                body: formData,
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error("Failed to upload file:", errorText);
                throw new Error("Failed to upload file.");
            }

            const data = await response.json();
            setJobId(data.task_id);
            setPolling(true);
        } catch (error) {
            console.error("Error uploading file:", error);
            alert("Error uploading file.");
        } finally {
            setUploading(false);
        }
    };


    // Poll for job status and results
    React.useEffect(() => {
        let interval;
        if (polling && jobId) {
            interval = setInterval(async () => {
                try {
                    const response = await fetch(`https://dellfi.serv.uni-hohenheim.de/backend/api/transcription/status/${jobId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (!response.ok) throw new Error("Failed to fetch job status.");

                    const data = await response.json();
                    console.log("Job Status Response:", data); // Debugging

                    if (data.status === "completed") {
                        setPolling(false);

                        const resultResponse = await fetch(
                            `https://dellfi.serv.uni-hohenheim.de/backend/api/transcription/result/${jobId}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            }
                        );

                        if (!resultResponse.ok) throw new Error("Failed to fetch transcription result.");

                        const result = await resultResponse.json();
                        console.log("Transcription Result:", result); // Debugging

                        // Properly set the transcript and timestamps
                        setTranscript(result.transcript || "No transcript available.");
                        setJsonTranscript(JSON.stringify(result.segments || [], null, 2));
                    }
                } catch (error) {
                    console.error("Error polling job status:", error);
                }
            }, 3000);
        }

        return () => clearInterval(interval);
    }, [polling, jobId, token]);


    // Handle copy to clipboard
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        setCopySuccess(true);
    };

    return (
        <Box className="transcription-container">
            <Typography variant="h5" gutterBottom>
                Transcription Service
            </Typography>
            <Typography variant="body1" color="textSecondary">
                Please note: Keep this Tab / Window open during transcription. If many people use the service simultaneously you may need to wait a bit.
            </Typography>
            {/* File Upload Section */}
            <Box className="upload-section">
                <Button
                    variant="outlined"
                    startIcon={<FileUploadIcon />}
                    component="label"
                >
                    Upload File
                    <input type="file" hidden onChange={handleFileChange} />
                </Button>
                <Typography variant="body1">
                    {selectedFile ? selectedFile.name : "No file selected"}
                </Typography>
            </Box>
    
            {/* Upload Button */}
            <Button
                variant="contained"
                color="primary"
                onClick={handleFileUpload}
                disabled={!selectedFile || uploading || polling}
                className="upload-button"
            >
                {uploading || polling ? <CircularProgress size={24} color="inherit" /> : "Start Transcription"}
            </Button>
    
            {/* Transcription Status */}
            {polling && (
                <Typography className="transcription-status">
                    Processing transcription... <CircularProgress size={16} />
                </Typography>
            )}
    
            {/* Transcription Results */}
            {transcript && (
                <Paper elevation={3} className="transcription-results">
                    <Typography variant="h6" gutterBottom>
                        Transcript
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => handleCopy(transcript)}
                            className="copy-button"
                        >
                            <CopyIcon />
                        </IconButton>
                    </Typography>
                    <TextField
                        multiline
                        fullWidth
                        value={transcript}
                        InputProps={{ readOnly: true }}
                    />
                </Paper>
            )}
    
            {/* Timestamps JSON */}
            {jsonTranscript && (
                <Paper elevation={3} className="transcription-results">
                    <Typography variant="h6" gutterBottom>
                        Timestamps (JSON)
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={() => handleCopy(jsonTranscript)}
                            className="copy-button"
                        >
                            <CopyIcon />
                        </IconButton>
                    </Typography>
                    <TextField
                        multiline
                        fullWidth
                        value={jsonTranscript}
                        InputProps={{ readOnly: true }}
                    />
                </Paper>
            )}
    
            {/* Copy Success Snackbar */}
            <Snackbar
                open={copySuccess}
                autoHideDuration={2000}
                onClose={() => setCopySuccess(false)}
                className="success-snackbar"
            >
                <Alert
                    onClose={() => setCopySuccess(false)}
                    severity="success"
                    variant="filled"
                >
                    Copied to clipboard!
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Transcription;
