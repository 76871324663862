import React, { useState } from 'react';
import { TextField, Button, CircularProgress, Box, Typography, IconButton } from '@mui/material';
import { ContentCopy } from '@mui/icons-material'; // For copy button icon
import './Summary.css';
import ReactMarkdown from 'react-markdown';

const Summary = () => {
  const [inputText, setInputText] = useState('');
  const [summaryText, setSummaryText] = useState('');
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const MAX_CHARACTERS = 30000; // Max 30k characters allowed
  const CHUNK_SIZE = 30001; // Max chunk size for each request

  const handleInputChange = (event) => {
    const { value } = event.target;
    // Ensure the value doesn't exceed the max character limit
    if (value.length <= MAX_CHARACTERS) {
      setInputText(value);
    } else {
      setInputText(value.slice(0, MAX_CHARACTERS)); // Trim excess characters
    }
  };
  
  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text");
  
    // Calculate the maximum allowed space
    const remainingSpace = MAX_CHARACTERS - inputText.length;
  
    // Trim the pasted text to fit within the remaining space
    const allowedText = pastedText.slice(0, remainingSpace);
  
    // Combine the current input with the allowed pasted text
    const newText = (inputText + allowedText).slice(0, MAX_CHARACTERS);
  
    // Set the new input text
    setInputText(newText);
  
    // Auto-start summarization after pasting
    setTimeout(() => {
      handleSummarize(newText); // Use the updated text
    }, 100); // Small delay to ensure inputText is updated
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(summaryText);
  };

  const handleSummarize = async (text = null) => {
    const input = text !== null ? text : inputText;
    if (!input || !input.trim()) return;

    setSummaryText('Summarizing... please wait.');
    setLoading(true);

    try {
      const finalSummary = await summarizeTextRecursively(input, false);
      setSummaryText(finalSummary);
    } catch (error) {
      console.error('Summarization failed:', error);
      setSummaryText('Failed to summarize the text.');
    } finally {
      setLoading(false);
    }
  };

  const summarizeTextRecursively = async (text, isFinalStep = false) => {
    console.log("Recursively summarizing text, Length:", text.length);

    // Step 1: Directly stream final summary if text length is within CHUNK_SIZE
    if (text.length <= CHUNK_SIZE) {
      if (isFinalStep) {
        setSummaryText(''); // Clear previous summary text
        await streamSummaryFromModel(text, (chunkSummary) => setSummaryText((prev) => prev + chunkSummary));
      } else {
        const completeSummary = await streamSummaryFromModel(text); // Silent processing, no streaming to UI
        return completeSummary;
      }
      return text;
    }

    // Step 2: Split and summarize each chunk if text is larger than CHUNK_SIZE
    const chunks = splitTextIntoChunksWithOverlap(text, CHUNK_SIZE);
    const intermediateSummaries = [];

    // Process each chunk sequentially and wait for each summary
    for (const chunk of chunks) {
      console.log("Summarizing chunk, Length:", chunk.length);
      const chunkSummary = await streamSummaryFromModel(chunk); // Wait for each complete summary
      intermediateSummaries.push(chunkSummary);
    }

    // Combine intermediate summaries and log length to ensure no loss
    const combinedSummary = intermediateSummaries.join('\n');
    console.log("Combined summary length after processing all chunks:", combinedSummary.length);

    // Step 3: Recursively reduce combined summary
    if (combinedSummary.length > CHUNK_SIZE) {
      return await summarizeTextRecursively(combinedSummary, false);
    } else {
      setSummaryText('Finalizing summary...');
      return await summarizeTextRecursively(combinedSummary, true);
    }
  };


  // Split text into chunks with an overlap of 50 characters for better coherence
  const splitTextIntoChunksWithOverlap = (text, size) => {
    const chunks = [];
    const overlap = 50;
    const numChunks = Math.ceil(text.length / size);

    // Adjust the chunk size to divide the text into equal parts
    const adjustedChunkSize = Math.ceil(text.length / numChunks);

    for (let i = 0; i < text.length; i += adjustedChunkSize - overlap) {
      const chunk = text.slice(i, i + adjustedChunkSize);
      console.log(`Chunk ${chunks.length + 1}: Start=${i}, End=${i + adjustedChunkSize}, Length=${chunk.length}`);
      chunks.push(chunk);
    }

    // Ensure any remaining text is added as the final chunk if necessary
    if (chunks.reduce((total, chunk) => total + chunk.length, 0) < text.length) {
      const finalChunk = text.slice(chunks.length * (adjustedChunkSize - overlap));
      console.log(`Final chunk: Start=${chunks.length * (adjustedChunkSize - overlap)}, Length=${finalChunk.length}`);
      chunks.push(finalChunk);
    }

    console.log(`Total chunks created: ${chunks.length}`);
    return chunks;
  };

  const cleanupSummary = (summary) => {
    // Remove <think>...</think> blocks and trim extra whitespace/newlines
    return summary.replace(/<think>.*?<\/think>/gs, '').trim();
  };  

  const streamSummaryFromModel = async (textChunk, onStreamUpdate = null) => {
    //const prompt = `Summarize the following text in its language and only answer with the summary and nothing more. Here is the text:  ${textChunk}[end] Now that you've seen the text - summarize the text it in its original language!`;
    const prompt = `Summarize the following text in its original language. Text: ${textChunk}`;

    console.log("Prompt: ", prompt);

    try {
      const response = await fetch('https://dellfi.serv.uni-hohenheim.de/backend/api/llm_inference', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ model: 'deepseek-r1:14b', prompt })
      });

      if (!response.ok) throw new Error('Summarization request failed.');

      const reader = response.body.getReader();
      let accumulatedData = '';
      let completeMessage = '';

      return new Promise((resolve, reject) => {
        const handleStream = ({ done, value }) => {
          if (done) {
            resolve(cleanupSummary(completeMessage)); // Resolve with the complete summary once done
            return;
          }

          const textDecoder = new TextDecoder("utf-8");
          accumulatedData += textDecoder.decode(value, { stream: true });

          while (true) {
            const jsonBoundary = accumulatedData.indexOf('}');
            if (jsonBoundary === -1) break;

            try {
              const jsonChunk = JSON.parse(accumulatedData.slice(0, jsonBoundary + 1));
              if (jsonChunk.response !== undefined) {
                const latestChunk = jsonChunk.response;
                completeMessage += latestChunk;

                if (onStreamUpdate) onStreamUpdate(latestChunk); // Stream only in final step
              }
              accumulatedData = accumulatedData.slice(jsonBoundary + 1);
            } catch (error) {
              break;
            }
          }

          reader.read().then(handleStream).catch((error) => {
            console.error('Error during streaming:', error);
            reject(error); // Reject the promise if any streaming error occurs
          });
        };

        reader.read().then(handleStream).catch((error) => {
          console.error('Error during streaming:', error);
          reject(error); // Reject the promise if any fetch error occurs
        });
      });
    } catch (error) {
      console.error('Summarization failed:', error);
      return '';
    }
  };


  return (
    <div className="summary-container">
      {/* Information box about text length limit */}
      <Box mb={2} p={2} border={1} borderColor="grey.500" borderRadius={4} bgcolor="grey.100">
        <Typography variant="body1" color="textSecondary">
          Please note: Summarizing very long texts may take some time to process. The current input limit per request is 30,000 characters. If you want the summary in a specific language or format add the instruction at the end of the text.
        </Typography>
        <Typography variant="body1" color="textSecondary">
        Example: Fasse den Text mit Überschriften und auf Deutsch zusammen!        
        </Typography>
      </Box>

      {/* Input and Output section */}
      <div className="input-output-wrapper">
        {/* Input Text Field */}
        <TextField
          label="Input"
          multiline
          rows={12}
          variant="outlined"
          fullWidth
          value={inputText}
          onChange={handleInputChange}
          onPaste={handlePaste}
          className="input-text-field"
          helperText={`Character count: ${inputText.length}/${MAX_CHARACTERS}`}
        />

        <Box
          className="output-text-field"
          p={2}
          border={1}
          borderColor="grey.300"
          borderRadius={4}
          bgcolor="grey.50"
          overflow="auto"
        >
          <ReactMarkdown>{summaryText}</ReactMarkdown>
        </Box>
      </div>

      {/* Summarize and Copy Buttons */}
      <div className="button-wrapper">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSummarize()}
          disabled={loading || !inputText.trim() || inputText.length > MAX_CHARACTERS}
          className="summarize-button"
        >
          {loading ? <CircularProgress size={24} /> : 'Summarize'}
        </Button>

        {/* Copy to Clipboard Button */}
        {summaryText && (
          <IconButton
            aria-label="copy to clipboard"
            onClick={copyToClipboard}
            className="copy-button"
          >
            <ContentCopy />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default Summary;
