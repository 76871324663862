import React, { useState, useEffect } from 'react';
import {
  TextField, Button, Container, Typography, IconButton,
  Grid, FormControlLabel, Checkbox, Alert
} from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import './CreateEditQuiz.css';
import { useParams, useNavigate } from 'react-router-dom';
import { Delete } from '@mui/icons-material';

const CreateEditQuiz = ({ onFormSubmit }) => {
  const { quizId } = useParams();
  const navigate = useNavigate();
  const [deletedQuestionIds, setDeletedQuestionIds] = useState([]);
  const [deletedAnswerIds, setDeletedAnswerIds] = useState([]);
  const [title, setTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [answerTimeLimit, setAnswerTimeLimit] = useState(45);
  const [questions, setQuestions] = useState([
    { text: '', answers: [{ text: '', isCorrect: false }] }
  ]);

  useEffect(() => {
    if (quizId && quizId !== 'new') {
      const fetchQuiz = async () => {
        try {
          const response = await fetch(`https://dellfi.serv.uni-hohenheim.de/backend/quiz/${quizId}/owned`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          if (response.ok) {
            const quiz = await response.json();
            console.log("Fetched Quiz Data:", quiz);

            setTitle(quiz.title);
            setAnswerTimeLimit(quiz.answerTimeLimit);
            setQuestions(
              quiz.questions.map((q) => ({
                ...q,
                text: q.text || '', // Ensure text is always a string
                answers: q.answers && q.answers.length > 0
                  ? q.answers.map(a => ({
                    ...a,
                    text: a.text || '', // Ensure text is always a string
                    isCorrect: q.correctAnswerId === a.id,
                  }))
                  : [{ text: '', isCorrect: false }]
              }))
            );
          } else {
            console.error('Error fetching quiz:', response.statusText);
            navigate('/edit-quiz/');
          }
        } catch (error) {
          console.error('Error fetching quiz:', error);
        }
      };
      fetchQuiz();
    }
  }, [quizId]);


  const handleSubmit = async (event) => {
    event.preventDefault();

    // Ensure each question has exactly one correct answer
    const updatedQuestions = questions.map((question) => {
      const correctAnswers = question.answers.filter((answer) => answer.isCorrect);

      if (correctAnswers.length === 0) {
        setErrorMessage("Each question must have exactly one correct answer.");
        return null;
      }

      if (correctAnswers.length > 1) {
        setErrorMessage("Each question can have only one correct answer.");
        return null;
      }

      return {
        ...question,
        correctAnswerId: correctAnswers[0].id || null,
      };
    });

    if (updatedQuestions.includes(null)) return; // Stop if validation fails

    setErrorMessage(""); // Clear previous errors if validation passes

    const quizData = {
      title, answerTimeLimit, questions: updatedQuestions, deletedQuestionIds,
      deletedAnswerIds
    };
    console.log("Sending Data:", JSON.stringify(quizData, null, 2));

    try {
      // Decide whether to create or update based on `quizId`
      const url = quizId && quizId !== 'new'
        ? `https://dellfi.serv.uni-hohenheim.de/backend/quiz/${quizId}/edit`
        : `https://dellfi.serv.uni-hohenheim.de/backend/quiz`;

      const method = quizId ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ quizData }),
      });

      if (response.ok) {
        const responseData = await response.json();

        // If creating a new quiz, update state with new `quizId`
        /*if (!quizId) {
          navigate(`/edit-quiz/${responseData.id}`); // Redirect to edit mode
        } else {
          navigate('/quizzes'); // Redirect back to quizzes list after edit
        }*/
        navigate('/quizzes');
      } else {
        setErrorMessage("Error saving quiz. Please try again.");
        console.error("Error saving quiz:", response.statusText);
      }
    } catch (error) {
      setErrorMessage("Error saving quiz. Please check your connection.");
      console.error("Error saving quiz:", error);
    }
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { text: '', answers: [{ text: '', isCorrect: false }] }]);
  };

  const handleAddAnswer = (questionIndex) => {
    const updatedQuestions = [...questions];
  
    // Ensure a question cannot have more than 4 answers
    if (updatedQuestions[questionIndex].answers.length >= 4) {
      return;
    }
  
    updatedQuestions[questionIndex].answers.push({ text: '', isCorrect: false });
    setQuestions(updatedQuestions);
  };

  const handleRemoveQuestion = (questionIndex) => {
    const questionToRemove = questions[questionIndex];
    if (questionToRemove.id) {
      setDeletedQuestionIds((prev) => [...prev, questionToRemove.id]);
    }
    setQuestions(questions.filter((_, index) => index !== questionIndex));
  };

  const handleRemoveAnswer = (questionIndex, answerIndex) => {
    const answerToRemove = questions[questionIndex].answers[answerIndex];
    if (answerToRemove.id) {
      setDeletedAnswerIds((prev) => [...prev, answerToRemove.id]);
    }

    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].answers = updatedQuestions[questionIndex].answers.filter((_, index) => index !== answerIndex);
    setQuestions(updatedQuestions);
  };

  const handleCorrectAnswerSelection = (questionIndex, answerIndex) => {
    const updatedQuestions = [...questions];

    updatedQuestions[questionIndex].answers = updatedQuestions[questionIndex].answers.map((answer, i) => ({
      ...answer,
      isCorrect: i === answerIndex,
    }));

    setQuestions(updatedQuestions);
  };

  const handleMoveQuestion = (index, direction) => {
    const updatedQuestions = [...questions];
    const newIndex = index + direction;

    if (newIndex < 0 || newIndex >= questions.length) return; // Prevent out-of-bounds swaps

    // Swap questions
    [updatedQuestions[index], updatedQuestions[newIndex]] = [updatedQuestions[newIndex], updatedQuestions[index]];
    setQuestions(updatedQuestions);
  };


  return (
    <Container className="create-edit-quiz">
      <Typography variant="h4" align="center" className="quiz-header">
        {quizId ? 'Edit' : 'Create'} Quiz
      </Typography>

      <form onSubmit={handleSubmit} className="quiz-form">
        <TextField
          label="Quiz Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          fullWidth
          margin="normal"
          className="input-field"
        />

        <TextField
          label="Answer Time Limit (seconds)"
          type="number"
          value={answerTimeLimit}
          onChange={(e) => setAnswerTimeLimit(Number(e.target.value))}
          required
          fullWidth
          margin="normal"
          inputProps={{ min: 10 }}
          className="input-field"
        />

        <Typography variant="h6" className="questions-title">
          Questions
        </Typography>
        <Grid container spacing={2} className="questions-container">
          {questions.map((question, qIndex) => (
            <Grid item xs={12} key={qIndex} className="question-card">
              <div className="question-header">
                <TextField
                  label={`Question ${qIndex + 1}`}
                  value={question.text || ''}
                  onChange={(e) => {
                    const updatedQuestions = [...questions];
                    updatedQuestions[qIndex].text = e.target.value;
                    setQuestions(updatedQuestions);
                  }}
                  required
                  fullWidth
                  className="input-field"
                />

                {/* Only render move buttons if there's more than one question. Top and bottom only get 1 buttn. */}
                {questions.length > 1 && (
                  <div className="move-buttons">
                    {qIndex > 0 && (
                      <IconButton onClick={() => handleMoveQuestion(qIndex, -1)} color="primary">
                        <ArrowUpward />
                      </IconButton>
                    )}
                    {qIndex < questions.length - 1 && (
                      <IconButton onClick={() => handleMoveQuestion(qIndex, 1)} color="primary">
                        <ArrowDownward />
                      </IconButton>
                    )}
                  </div>
                )}

                <IconButton
                  onClick={() => handleRemoveQuestion(qIndex)}
                  color="error"
                  className="delete-question-btn"
                >
                  <Delete />
                </IconButton>
              </div>

              <Typography variant="body1" className="answers-title">Answers:</Typography>
              {question.answers.map((answer, aIndex) => (
                <Grid container key={aIndex} alignItems="center" className="answer-row">
                  <Grid item xs={9}>
                    <TextField
                      label={`Answer ${aIndex + 1}`}
                      value={answer.text}
                      onChange={(e) => {
                        const updatedQuestions = [...questions];
                        updatedQuestions[qIndex].answers[aIndex].text = e.target.value;
                        setQuestions(updatedQuestions);
                      }}
                      required
                      fullWidth
                      className="input-field"
                    />
                  </Grid>
                  <Grid item xs={2} className="correct-answer-checkbox">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!answer.isCorrect}
                          onChange={() => handleCorrectAnswerSelection(qIndex, aIndex)}
                        />
                      }
                      label="Correct"
                    />
                  </Grid>
                  <Grid item xs={1} className="delete-answer">
                    <IconButton
                      onClick={() => handleRemoveAnswer(qIndex, aIndex)}
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              {question.answers.length < 4 && (
                <Button onClick={() => handleAddAnswer(qIndex)} variant="outlined" color="secondary" className="add-answer-btn">
                  Add Answer
                </Button>
              )}
            </Grid>
          ))}
        </Grid>

        <Button onClick={handleAddQuestion} variant="outlined" color="primary" className="add-question-btn">
          Add Question
        </Button>

        <Button type="submit" variant="contained" color="primary" className="submit-btn">
          {quizId ? 'Save Changes' : 'Create Quiz'}
        </Button>
      </form>

      {errorMessage && (
        <Alert severity="error" className="error-message">
          {errorMessage}
        </Alert>
      )}
    </Container>
  );
};

export default CreateEditQuiz;
