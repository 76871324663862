import React, { useState } from 'react';
import { TextField, Button, MenuItem, Select, FormControl, InputLabel, CircularProgress, Box, Typography } from '@mui/material';
import './Translation.css';

const Translation = () => {
  const [inputText, setInputText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [targetLanguage, setTargetLanguage] = useState('English');
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const MAX_CHARACTERS = 5000;

  const handleLanguageChange = (event) => {
    setTargetLanguage(event.target.value);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    // Enforce the character limit
    if (value.length <= MAX_CHARACTERS) {
      setInputText(value);
    } else {
      setInputText(value.slice(0, MAX_CHARACTERS)); // Truncate text if it exceeds the limit
    }
  };

  const handlePaste = (event) => {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the pasted text from the clipboard
    const pastedText = (event.clipboardData || window.clipboardData).getData('text');

    // Enforce the character limit
    const truncatedText = pastedText.slice(0, MAX_CHARACTERS);
    setInputText(truncatedText);

    // Trigger translation with the truncated text
    setTimeout(() => {
      handleTranslate(truncatedText); // Pass the truncated text directly to the translation function
    }, 100); // A slight delay to ensure the state is updated
  };

  const handleTranslate = async (text = null) => {
    const input = text !== null ? text : inputText;  // Use passed text if provided, otherwise fallback to state

    if (!input || !input.trim()) {
      console.error("Input is either empty or invalid:", input);
      return;
    }

    const prompt = `Translate the following text to ${targetLanguage} and only answer with the text translated into ${targetLanguage} and nothing more. Here is the text: ${input}`;
    setTranslatedText('');
    setLoading(true);

    try {
      const response = await fetch('https://dellfi.serv.uni-hohenheim.de/backend/api/llm_inference', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ model: 'mistral-nemo', prompt })
      });

      if (!response.ok) throw new Error('Translation request failed.');

      const reader = response.body.getReader();
      let completeMessage = '';
      let accumulatedData = '';

      function handleStream({ done, value }) {
        if (done) {
          setLoading(false);
          setTranslatedText(completeMessage);
          return;
        }

        const textDecoder = new TextDecoder("utf-8");
        accumulatedData += textDecoder.decode(value, { stream: true });

        while (true) {
          const jsonBoundary = accumulatedData.indexOf('}');
          if (jsonBoundary === -1) break;

          try {
            const jsonChunks = JSON.parse(accumulatedData.slice(0, jsonBoundary + 1));
            if (jsonChunks.response !== undefined) {
              completeMessage += jsonChunks.response;
              setTranslatedText(completeMessage);
            }
            accumulatedData = accumulatedData.slice(jsonBoundary + 1);
          } catch (e) {
            break;
          }
        }

        reader.read().then(handleStream).catch(() => {
          setTranslatedText('Error during streaming translation.');
          setLoading(false);
        });
      }

      reader.read().then(handleStream);

    } catch (error) {
      console.error('Translation failed:', error);
      setTranslatedText('Failed to translate the text.');
      setLoading(false);
    }
  };

  return (
    <div className="translation-container">
      <FormControl variant="outlined" className="language-dropdown">
      <Box mb={2} p={2} border={1} borderColor="grey.500" borderRadius={4} bgcolor="grey.100">
        <Typography variant="body1" color="textSecondary">
          Please note: If you are missing your langauge or if you are not satisfied with translation quality contact 
          <a target="_blank" href="https://komm.uni-hohenheim.de/reich"> Andreas Reich</a> via email.
        </Typography>
      </Box>

        <InputLabel>Translate to</InputLabel>
        <Select
          value={targetLanguage}
          onChange={handleLanguageChange}
          label="Translate to"
          renderValue={(selected) => selected}  // Keep this for a selected view
          className="desktop-language-grid" // Add this class for desktop customization
        >
          <MenuItem value="English">English</MenuItem>
          <MenuItem value="Deutsch">Deutsch</MenuItem>
          <MenuItem value="Afrikaans">Afrikaans</MenuItem>
          <MenuItem value="Albanian">Albanian</MenuItem>
          <MenuItem value="Amharic">Amharic</MenuItem>
          <MenuItem value="Arabic">Arabic</MenuItem>
          <MenuItem value="Armenian">Armenian</MenuItem>
          <MenuItem value="Azerbaijani">Azerbaijani</MenuItem>
          <MenuItem value="Bengali">Bengali</MenuItem>
          <MenuItem value="Bosnian">Bosnian</MenuItem>
          <MenuItem value="Bulgarian">Bulgarian</MenuItem>
          <MenuItem value="Catalan">Catalan</MenuItem>
          <MenuItem value="Chinese (Simplified)">Chinese (Simplified)</MenuItem>
          <MenuItem value="Chinese (Traditional)">Chinese (Traditional)</MenuItem>
          <MenuItem value="Croatian">Croatian</MenuItem>
          <MenuItem value="Czech">Czech</MenuItem>
          <MenuItem value="Danish">Danish</MenuItem>
          <MenuItem value="Dutch">Dutch</MenuItem>
          <MenuItem value="English (American)">English (American)</MenuItem>
          <MenuItem value="English (British)">English (British)</MenuItem>
          <MenuItem value="Estonian">Estonian</MenuItem>
          <MenuItem value="Finnish">Finnish</MenuItem>
          <MenuItem value="French">French</MenuItem>
          <MenuItem value="Georgian">Georgian</MenuItem>
          <MenuItem value="Deutsch">German (Deutsch)</MenuItem>
          <MenuItem value="Greek">Greek</MenuItem>
          <MenuItem value="Gujarati">Gujarati</MenuItem>
          <MenuItem value="Haitian Creole">Haitian Creole</MenuItem>
          <MenuItem value="Hebrew">Hebrew</MenuItem>
          <MenuItem value="Hindi">Hindi</MenuItem>
          <MenuItem value="Hungarian">Hungarian</MenuItem>
          <MenuItem value="Icelandic">Icelandic</MenuItem>
          <MenuItem value="Indonesian">Indonesian</MenuItem>
          <MenuItem value="Irish">Irish</MenuItem>
          <MenuItem value="Italian">Italian</MenuItem>
          <MenuItem value="Japanese">Japanese</MenuItem>
          <MenuItem value="Kannada">Kannada</MenuItem>
          <MenuItem value="Kazakh">Kazakh</MenuItem>
          <MenuItem value="Khmer">Khmer</MenuItem>
          <MenuItem value="Korean">Korean</MenuItem>
          <MenuItem value="Kurdish (Kurmanji)">Kurdish (Kurmanji)</MenuItem>
          <MenuItem value="Kyrgyz">Kyrgyz</MenuItem>
          <MenuItem value="Lao">Lao</MenuItem>
          <MenuItem value="Latin">Latin</MenuItem>
          <MenuItem value="Latvian">Latvian</MenuItem>
          <MenuItem value="Lithuanian">Lithuanian</MenuItem>
          <MenuItem value="Luxembourgish">Luxembourgish</MenuItem>
          <MenuItem value="Macedonian">Macedonian</MenuItem>
          <MenuItem value="Malay">Malay</MenuItem>
          <MenuItem value="Malayalam">Malayalam</MenuItem>
          <MenuItem value="Maltese">Maltese</MenuItem>
          <MenuItem value="Marathi">Marathi</MenuItem>
          <MenuItem value="Mongolian">Mongolian</MenuItem>
          <MenuItem value="Norwegian (Bokmål)">Norwegian (Bokmål)</MenuItem>
          <MenuItem value="Pashto">Pashto</MenuItem>
          <MenuItem value="Persian">Persian</MenuItem>
          <MenuItem value="Polish">Polish</MenuItem>
          <MenuItem value="Portuguese">Portuguese</MenuItem>
          <MenuItem value="Portuguese (Brazilian)">Portuguese (Brazilian)</MenuItem>
          <MenuItem value="Punjabi">Punjabi</MenuItem>
          <MenuItem value="Romanian">Romanian</MenuItem>
          <MenuItem value="Russian">Russian</MenuItem>
          <MenuItem value="Serbian">Serbian</MenuItem>
          <MenuItem value="Sinhala">Sinhala</MenuItem>
          <MenuItem value="Slovakian">Slovakian</MenuItem>
          <MenuItem value="Slovenian">Slovenian</MenuItem>
          <MenuItem value="Somali">Somali</MenuItem>
          <MenuItem value="Spanish">Spanish</MenuItem>
          <MenuItem value="Swahili">Swahili</MenuItem>
          <MenuItem value="Swedish">Swedish</MenuItem>
          <MenuItem value="Tagalog">Tagalog</MenuItem>
          <MenuItem value="Tamil">Tamil</MenuItem>
          <MenuItem value="Telugu">Telugu</MenuItem>
          <MenuItem value="Thai">Thai</MenuItem>
          <MenuItem value="Turkish">Turkish</MenuItem>
          <MenuItem value="Ukrainian">Ukrainian</MenuItem>
          <MenuItem value="Urdu">Urdu</MenuItem>
          <MenuItem value="Uzbek">Uzbek</MenuItem>
          <MenuItem value="Vietnamese">Vietnamese</MenuItem>
          <MenuItem value="Welsh">Welsh</MenuItem>
          <MenuItem value="Yoruba">Yoruba</MenuItem>
          <MenuItem value="Zulu">Zulu</MenuItem>

        </Select>
      </FormControl>

      <div className="input-output-wrapper">
        <TextField
          label="Input"
          multiline
          rows={12}
          variant="outlined"
          fullWidth
          value={inputText}
          onChange={handleInputChange}
          onPaste={handlePaste}
          helperText={`Character count: ${inputText.length}/${MAX_CHARACTERS}`}
          className="input-text-field"
        />

        <TextField
          label="Output"
          multiline
          rows={12}
          variant="outlined"
          fullWidth
          value={translatedText}
          InputProps={{ readOnly: true }}
          className="output-text-field"
        />
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => handleTranslate()}  // Use state value if called via button
        disabled={loading || !inputText.trim()}  // Ensure the button is disabled when inputText is empty
        className="translate-button"
      >
        {loading ? <CircularProgress size={24} /> : 'Translate'}
      </Button>
    </div>
  );
};

export default Translation;
