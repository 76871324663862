import { React, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useContext } from "react";
import { AuthContext } from '../AuthContext';

import './Navbar.css';

const Navbar = () => {

    const { authenticatedUser, setAuthenticatedUser } = useContext(AuthContext);

    const [isNavbarExpanded, setIsNavbarExpanded] = useState(
        () => JSON.parse(localStorage.getItem("navbarExpanded")) ?? true
    )

    useEffect(() => {
        localStorage.setItem("navbarExpanded", JSON.stringify(isNavbarExpanded));
    }, [isNavbarExpanded]);

    const handleLogout = () => {
        // remove the token
        localStorage.removeItem("token");
        // unset the authenticated user
        setAuthenticatedUser(null);
    };

    const toggleNavbar = () => {
        setIsNavbarExpanded((prev) => !prev);
    };

    return (
        <div>
            {authenticatedUser ? (
                <div className={isNavbarExpanded ? "navbar-expanded" : "navbar-retracted"}>
                    <nav className="navbar-authed">
                        <Link to="/" className="logo">
                            <img src={`${process.env.PUBLIC_URL}/pet_logo_1.jpg`} alt="logo" />
                        </Link>
                        <div className="nav-links">
                            {/* <Link to="/about" className="nav-item">Über uns</Link> */}
{/*                             <Link to="/quizzes" className="nav-item">My Quizzes</Link>
                            <Link to="/image-generation" className="nav-item">Generate Image</Link> */}
                            <Link to="/translate" className="nav-item">Translate</Link>
                            <Link to="/summary" className="nav-item">Summarize</Link>
                            <Link to="/transcribe" className="nav-item">Transcribe</Link>
                            <Link to="/voice" className="nav-item">Voice Synthesis</Link>
                            {/* add more nav links here */}
                        </div>
                        <div className="auth-buttons">
                            <Link to="/settings" className="nav-item">
                                <img id="cogwheel" src={`${process.env.PUBLIC_URL}/cogwheel.png`} alt="settings" />
                            </Link>
                            <button className="sign-in" onClick={handleLogout}>Logout</button>
                        </div>
                    </nav>
                    <div className="expand-btn-container">
                        <button className="expand-btn" onClick={toggleNavbar}>
                            {isNavbarExpanded ? "⬆" : "⬇"}
                        </button>
                    </div>
                </div>
            ) :
                (
                    <div>
                        <nav className="navbar">
                            <Link to="/" className="logo">
                                <img src={`${process.env.PUBLIC_URL}/pet_logo_1.jpg`} alt="logo" />
                            </Link>
                            <div className="nav-links">
                                <Link to="/about" className="nav-item">Über uns</Link>
                                {/* add more nav links here */}
                            </div>
                            <div className="auth-buttons">
                                <Link to="/signup" className="sign-up">Registrieren</Link>
                                <Link to="/login" className="sign-in">Login</Link>
                            </div>
                        </nav>
                    </div>
                )}
        </div>
    );
}

export default Navbar;
